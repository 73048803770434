<template>
    <v-container fluid>
        <v-row no-gutters>
            <v-col cols="12">
                <v-form class="custom-form" @submit.prevent="getTestReceipts">
                    <v-card elevation="1" color="grey lighten-4">
                        <v-card-text class="py-0">
                            <v-row dense>
                                <v-col cols="1" class="text-center">Doctor</v-col>
                                <v-col cols="2">
                                    <v-combobox
                                        dense
                                        outlined
                                        hide-details
                                        @focus="$store.dispatch('doctor/getDoctors')"
                                        :loading="$store.getters['doctor/loading']"
                                        :items="[{id: null, display_text: 'All'}].concat($store.getters['doctor/doctors'])"
                                        v-model="selectedDoctor"
                                        item-text="display_text"
                                        item-value="id"
                                        class="custom-form"
                                    ></v-combobox>
                                </v-col>
                                <v-col cols="1" class="text-right">Form Date</v-col>
                                <v-col cols="2">
                                    <v-menu>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                dense
                                                outlined
                                                hide-details
                                                v-model="filter.fromDate"
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                            <v-icon slot="prepend-inner">mdi-calendar-month</v-icon>
                                            </v-text-field>
                                        </template>
                                        <v-date-picker v-model="filter.fromDate"></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="1" class="text-right">To Date</v-col>
                                <v-col cols="2">
                                    <v-menu>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                dense
                                                outlined
                                                hide-details
                                                v-model="filter.toDate"
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                            <v-icon slot="prepend-inner">mdi-calendar-month</v-icon>
                                            </v-text-field>
                                        </template>
                                        <v-date-picker v-model="filter.toDate"></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col md="3">
                                    <v-btn type="submit" color="info" small style="margin-top:-1px" class="mr-1">Search</v-btn>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-form>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="12">
                <v-btn small color="blue-grey lighten-4" @click.prevent="print">Print</v-btn>
            </v-col>

            <v-col cols="12" id="printContent">
                <v-simple-table dense>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th>Receipt No.</th>
                                <th>Date</th>
                                <th>Patient Name</th>
                                <th>Reffered By</th>
                                <th>Sub Total</th>
                                <th>Discount</th>
                                <th>Total</th>
                                <th>Paid</th>
                                <th>Due</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(testReceipt, ind) in $store.getters['testReceipt/testReceipts']" :key="ind">
                                <td>{{ testReceipt.code }}</td>
                                <td>{{ testReceipt.bill_date }}</td>
                                <td>{{ testReceipt.patient ? testReceipt.patient.name : '' }}</td>
                                <td>{{ testReceipt.doctor ? testReceipt.doctor.name : 'NA'}}</td>
                                <td class="text-right">{{ testReceipt.sub_total_amount }}</td>
                                <td class="text-right">{{ testReceipt.discount_amount }}</td>
                                <td class="text-right">{{ testReceipt.total_amount }}</td>
                                <td class="text-right">{{ testReceipt.paid_amount }}</td>
                                <td class="text-right">{{ testReceipt.due_amount }}</td>
                            </tr>
                            <tr>
                                <td colspan="4" class="text-right"><strong>Total = </strong></td>
                                <td class="text-right"><strong>{{ totalSubtotal }}</strong></td>
                                <td class="text-right"><strong>{{ totalDiscount }}</strong></td>
                                <td class="text-right"><strong>{{ total }}</strong></td>
                                <td class="text-right"><strong>{{ totalPaid }}</strong></td>
                                <td class="text-right"><strong>{{ totalDue }}</strong></td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>

            <v-col cols="12" class="pb-0">
                <v-data-table
                    class="custom-data-table elevation-1"
                    dense
                    :headers="testReceiptHeader"
                    :loading="$store.getters['testReceipt/loading']"
                    :items="$store.getters['testReceipt/testReceipts']"
                >
                    <template v-slot:top>
                        <v-toolbar dense color="white" :elevation="1" height="30px">
                            <v-toolbar-title class="subtitle-2">Test Receipt List</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-form class="custom-form">
                                <v-text-field
                                    outlined
                                    dense
                                    hide-details
                                    placeholder="Search Test Receipt"
                                    append-icon="mdi-magnify"
                                    style="width:300px;"
                                >
                                </v-text-field>
                            </v-form>
                        </v-toolbar>
                    </template>
                    <template v-slot:[`item.doctor.name`]="{ item }">
                        {{ item.doctor != null ? item.doctor.name : 'N/A' }}
					</template>
                    <template v-slot:[`item.action`]="{ item }">
						<v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<v-icon small @click="$router.push(`/account/test-receipt-slip/${item.id}`)" color="primary" v-on="on">mdi-file</v-icon>
							</template>
							<span>View</span>
						</v-tooltip>
                        <v-tooltip v-if="userType != 'user'" bottom>
							<template v-slot:activator="{ on }">
								<v-icon small @click="$router.push(`/account/test-receipt/${item.id}`)" color="primary" v-on="on">mdi-circle-edit-outline</v-icon>
							</template>
							<span>Edit</span>
						</v-tooltip>
						<v-tooltip v-if="userType != 'user'" bottom>
							<template v-slot:activator="{ on }">
								<v-icon small @click="openDeleteDialog(item.id)" color="error" v-on="on" >mdi-delete-circle-outline</v-icon>
							</template>
							<span>Delete</span>
						</v-tooltip>
					</template>
                    <template v-slot:[`body.append`]>
                        <tr>
                            <td colspan="3"></td>
                            <td class="text-center"><b>Total =</b></td>
                            <td><b>{{ totalSubtotal }}</b></td>
                            <td><b>{{ totalDiscount }}</b></td>
                            <td><b>{{ total }}</b></td>
                            <td><b>{{ totalPaid }}</b></td>
                            <td><b>{{ totalDue }}</b></td>
                        </tr>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>

        <delete-confirm ref="deleteDialog" @confirm="deleteTestReceipt"></delete-confirm>

    </v-container>
</template>

<script>
import DeleteConfirm from '@/components/Confirm'

export default {
    name: 'SupplierEntry',
    components: {
        DeleteConfirm,
    },
    data: ()=> ({
        testReceiptHeader: [
            { text: 'Receipt No.', value: 'code' },
            { text: 'Date', value: 'bill_date' },
            { text: 'Patient Name', value: 'patient.name' },
            { text: 'Reffered By', value: 'doctor.name' },
            { text: 'Sub Total', value: 'sub_total_amount' },
            { text: 'Discount', value: 'discount_amount' },
            { text: 'Total', value: 'total_amount' },
            { text: 'Paid', value: 'paid_amount' },
            { text: 'Due', value: 'due_amount' },
            { text: 'Action', value: 'action' },
        ],

        selectedDoctor: null,

        filter: {
            fromDate: new Date().toISOString().substr(0, 10),
            toDate: new Date().toISOString().substr(0, 10),
        },

        testReceiptDeleteId: null,
        userType: ''
    }),
    computed: {
        totalSubtotal() {
            return this.$store.getters['testReceipt/testReceipts'].reduce((p, c) => { return +p + +c.sub_total_amount }, 0).toFixed(2)
        },
        totalDiscount() {
            return this.$store.getters['testReceipt/testReceipts'].reduce((p, c) => { return +p + +c.discount_amount }, 0).toFixed(2)
        },
        total() {
            return this.$store.getters['testReceipt/testReceipts'].reduce((p, c) => { return +p + +c.total_amount }, 0).toFixed(2)
        },
        totalPaid() {
            return this.$store.getters['testReceipt/testReceipts'].reduce((p,c) => { return +p + + c.paid_amount }, 0).toFixed(2)
        },
        totalDue() {
            return this.$store.getters['testReceipt/testReceipts'].reduce((p,c) => { return +p + + c.due_amount }, 0).toFixed(2)
        }
    },
    watch: {
        selectedDoctor(doctor) {
            if (doctor == null) return
            if (doctor.display_text == 'All') this.$delete(this.filter, 'doctor_id')
            else this.filter.doctor_id = doctor.id
        }  
    },
    created() {
        this.getTestReceipts();
        this.setStyle();
        this.getCompanyProfile();
        let userData = JSON.parse(localStorage.getItem('_userData'))
        this.userType = userData.user_type
    },
    methods: {
        getCompanyProfile() {
            this.$store.dispatch('companyProfile/getCompanyProfile')
        },

        getTestReceipts() {
            this.$store.dispatch("testReceipt/getTestReceipts", { ...this.filter });
        },

        openDeleteDialog(id) {
            this.testReceiptDeleteId = id
            this.$refs.deleteDialog.dialog = true
        },

        deleteTestReceipt() {
            this.$store.dispatch('testReceipt/deleteTestReceipt', {id: this.testReceiptDeleteId})
            this.$refs.deleteDialog.dialog = false
            this.testReceiptDeleteId = null
        },

        async print(){
            let invoiceContent = document.querySelector('#printContent').innerHTML;
            let printWindow = window.open('', 'PRINT', `width=${screen.width}, height=${screen.height}, left=0, top=0`);
            let ImagePath = this.apiBasePath +`/`+ this.$store.getters['companyProfile/company'].image;
            let companyName = this.$store.getters['companyProfile/company'].company_name;
            let address = this.$store.getters['companyProfile/company'].address;
            let contactUs = this.$store.getters['companyProfile/company'].contact_us;
            let email = this.$store.getters['companyProfile/company'].email;
            printWindow.document.write(`
                <!DOCTYPE html>
                <html lang="en">
                <head>
                    <meta charset="UTF-8">
                    <meta name="viewport" content="width=device-width, initial-scale=1.0">
                    <meta http-equiv="X-UA-Compatible" content="ie=edge">
                    <title>Investigation Payment Slip</title>
                    <style>
                    </style>
                </head>
                <body>
                    <div>
                        <div class="container">
                            <table style="width:100%;">
                                <thead>
                                    <tr>
                                        <td>
                                            <div class="custom-row">
                                                <div class="col-xs-2">
                                                    <img src="`+ImagePath+`" alt="Logo" style="height:90px; width:90%;object-fit: contain;" />
                                                </div>
                                                <div class="col-xs-10">
                                                    <strong style="font-size:18px;">${companyName}</strong><br>
                                                    <p style="white-space:pre-line;margin:0px">Address : ${address}</p>
                                                    <p style="margin:0px">Mobile : ${contactUs}</p>
                                                    <p style="margin:0px">E-mail : ${email}</p>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div class="custom-row">
                                                <div class="invoice-title">
                                                    Test Receipt Record
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="custom-row">
                                                <div class="col-xs-12">
                                                    ${invoiceContent}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td>
                                            <div style="width:100%;height:50px;">&nbsp;</div>
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>    
                    
                </body>
                </html>
            `);
            
            let invoiceStyle = printWindow.document.createElement('style');
            invoiceStyle.innerHTML = this.style.innerHTML;
            printWindow.document.head.appendChild(invoiceStyle);
            printWindow.moveTo(0, 0);
            
            printWindow.focus();
            await new Promise(resolve => setTimeout(resolve, 1000));
            printWindow.print();
            printWindow.close();
        },

        setStyle(){
            this.style = document.createElement('style');
            this.style.innerHTML = `
                .custom-row {
                    width: 100%;
                    display: block;
                }
                .print-btn a{
                    background: #CFD8DC;
                    display: inline-block;
                    padding: 3px 13px;
                    border-radius: 5px;
                    color: #000 !important;
                }
                .print-btn a:hover {
                    background: #B0BEC5;
                }
                .invoice-title {
                    text-align: center;
                    font-weight: bold;
                    font-size: 15px;
                    margin-bottom: 15px;
                    padding: 5px;
                    border-top: 1px dotted #454545;
                    border-bottom: 1px dotted #454545;
                }
                .col-xs-12 {
                    width: 100%;
                }
                .col-xs-10 {
                    width: 80%;
                    float: left;
                }
                .col-xs-9 {
                    width: 70%;
                    float: left;
                }
                .col-xs-7 {
                    width: 60%;
                    float: left;
                }
                .col-xs-6 {
                    width: 50%;
                    float: left;
                }
                .col-xs-5 {
                    width: 40%;
                    float: left;
                }
                .col-xs-4 {
                    width: 35%;
                    float: left;
                }
                .col-xs-3 {
                    width: 30%;
                    float: left;
                }
                .col-xs-2 {
                    width: 20%;
                    float: left;
                }
                .b-text {
                    font-weight: 500;
                    font-size: 15px;
                }
                .normal-text {
                    font-size: 14px;
                    margin: 0px;
                }
                .invoice-details {
                    width: 100%;
                    border-collapse: collapse;
                    border:1px solid #ccc;
                }
                .invoice-details thead {
                    font-weight: 500;
                    text-align:center;
                }
                .invoice-details tbody td{
                    padding: 0px 5px;
                }
                .text-center {
                    text-align: center;
                }
                .text-right {
                    text-align: right;
                }
                .line {
                    border-bottom: 1px solid #ccc;
                    margin-top: 15px;
                    margin-bottom: 15px;
                }
                .paid-text {
                    padding:30px 0px;
                }
                .mt-60 {
                    margin-top: 60px;
                }
                .mr-20 {
                    margin-right: 20px;
                }
                .ml-20 {
                    margin-left: 20px;
                }
                .ft-fiext {
                    position: fixed;
                    bottom: 0;
                }
                @media print {
                    .hide {
                        display: none
                    }
                    .v-data-table > .v-data-table__wrapper > table {
                        width: 100%;
                        border-spacing: 0;
                    }
                    .theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
                        font-size: 13px;
                        padding: 0px 1px;
                        color: #000;
                        border: 1px solid #ccc;
                        text-align: center;
                        height: 24px !important;
                    }
                    .v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td {
                        height: 20px !important;
                        padding: 0px 5px !important;
                        font-size: 12px !important;
                        border: 1px solid #ccc !important;
                    }
                }
                .cls {
                    clear: both;
                }
            `;
            document.head.appendChild(this.style);
        },
    }
}
</script>

<style lang="scss" scoped>
    .v-icon.v-icon {
       font-size: 18px !important;
       top: -3px !important;
    }
    .v-icon.v-icon[data-v-1f38b4e5] {
        font-size: 14px !important;
        top: 0px !important; 
    }
    .v-messages {
        flex: 1 1 auto;
        font-size: 12px;
        min-height: 0px !important;
        max-height: 0px!important;
        min-width: 1px;
        position: relative;
    }
    #printContent {
        display: none;
    }
    @media print {
        #printContent {
            display: block;
        }
    }
</style>